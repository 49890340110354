<template>
  <div class="dialog-container">
    <el-dialog
      :title="`${$t('areaMaintain')}`"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="1150px"
    >
      <el-row>
        <el-form ref="formTop" :model="formTop" label-width="0px">
          <el-row>
            <el-col :span="24">
              <el-form-item
                prop="sportsProjectName"
                label="运动项目："
                label-width="100px"
              >
                <el-col :span="24">
                  <el-row>
                    <el-col :span="24">
                      <el-radio-group
                        class="venue-radio-wrapper"
                        @change="onChangeCode($event)"
                        v-model="sportRadio"
                        :max="1"
                      >
                        <el-radio-button
                          class="radio"
                          v-for="item in sportList"
                          :key="item.sportsProjectCode"
                          :label="item.sportsProjectCode"
                          >{{ item.sportsProjectName }}</el-radio-button
                        >
                      </el-radio-group>
                    </el-col>
                  </el-row>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
      <el-table :data="fieldData" border style="width: 100%">
        <el-table-column
          prop="spaceCode"
          type="index"
          align="center"
          width="130"
          label="场地编码"
          :index="indexMethod"
        >
          <template slot-scope="scope">
            <el-input
              clearable
              size="mini"
              style="width: 105px"
              v-model="scope.row.spaceCode"
              :maxlength="10"
              placeholder="请输入内容"
            ></el-input>
            <!-- <el-form-item>{{ scope.row.spaceCode || '请输入场地编号' }}</el-form-item> -->
          </template>
        </el-table-column>
        <el-table-column
          prop="spaceName"
          label="场地名称"
          align="center"
          min-width="120"
        >
          <template slot-scope="scope">
            <el-input
              clearable
              size="mini"
              style="width: 120px"
              v-model="scope.row.spaceName"
              :maxlength="10"
              placeholder="请输入内容"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          label="每时段默认价格"
          align="center"
          min-width="130"
        >
          <template slot-scope="scope">
            <el-input-number
              clearable
              style="width: 109px"
              size="mini"
              v-model="scope.row.price"
              type="number"
              :step-strictly="true"
              :max="100000"
              :min="0.0"
              :step="0.01"
              placeholder="请输入内容"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          label="每时段会员价格"
          align="center"
          min-width="130"
        >
          <template slot-scope="scope">
            <el-input-number
              clearable
              style="width: 109px"
              size="mini"
              v-model="scope.row.vipPrice"
              type="number"
              :step-strictly="true"
              :max="100000"
              :min="0.0"
              :step="0.01"
              placeholder="请输入内容"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column
          prop="cameraList"
          align="center"
          min-width="100"
          label="直播摄像头"
        >
          <template slot-scope="scope">
            <!-- <el-select v-model="fieldData[scope.$index].liveCamerCode" style="width:100%" @change="getValue"> -->
            <el-select
              v-model="fieldData[scope.$index].iotId"
              style="width: 100%"
              @change="getValue()"
            >
              <el-option
                v-for="item in scope.row.cameraList"
                :key="item.id"
                :label="item.deviceName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          prop="cameraPrice"
          align="center"
          min-width="100"
          label="直播视频服务价格"
        >
          <template slot-scope="scope">
            <!-- <el-input-number clearable style="width:109px" size="mini" v-model="scope.row.cameraPrice" type="number" :step-strictly="true" :max="100000" :min="0.00" :step="0.01" placeholder="请输入内容"></el-input-number> -->
            <el-input-number
              @change="handleChange"
              clearable
              style="width: 109px"
              :value="spaceCameraPrice"
              size="mini"
              v-model="fieldData[scope.$index].spaceCameraPrice"
              type="number"
              :step-strictly="true"
              :max="100000"
              :min="0.0"
              :step="0.01"
              placeholder="请输入内容"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" min-width="168">
          <template slot-scope="scope">
            <el-button
              style="width: 80px"
              size="mini"
              v-if="scope.$index === fieldData.length - 1"
              @click="handleAdd"
              >新增</el-button
            >
            <el-button
              style="width: 80px"
              size="mini"
              type="danger"
              v-if="buttonAuth.includes('venueMana:venue:groundRemove')"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{
          $t("sure")
        }}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{
          $t("cancel")
        }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import mixin from "@/mixins/dialogMixin";
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      spaceCameraPrice: "",
      deviceCode: "",
      sportList: [],
      sportRadio: "", // 运动项目选中值
      cameraList: [
        {
          deviceCode: "",
          deviceName: "",
          deviceType: "",
          id: "",
          venueName: "",
        },
      ],
      sportsProjectCode: "",
      checkList: [],
      formTop: {
        sportsProjectCode: "",
        sportsProjectName: "",
      },
      deeps: [
        { value: 0, label: "省" },
        { value: 1, label: "市" },
        { value: 2, label: "区/县" },
      ],
      fieldData: [], // 实例化一个表单的变量
      rules: new AddDialogClass("rule", this.Language), // 实例化一个表单的规则
      roleData: [], // 角色树状的值
      apis,
      showMap: true,
      venuesId: "",
      isRequired: true,
      copyData: null,
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "TOKEN", "venueId"]),
  },
  methods: {
    getCemera(e) {
      // this.deviceCode = e.deviceCode
      console.log("e=========>", e);
    },
    /**
     * @function 初始化弹窗
     */
    init() {
      // this.$refs.form.resetFields();
      this.form = new AddDialogClass("form");
      console.log("我初始化了");
    },
    getValue(v) {
      console.log("v", v);
      console.log("fieldData-----点击获取数据", this.fieldData);
      this.deviceCode = v;
    },
    handleAdd() {
      this.fieldData.push({
        spaceCode: this.fieldData.length + 1,
        venueId: this.venuesId,
        price: "",
        spaceName: "",
      });
    },
    handleDelete(index, row) {
      if (row.id) {
        this.$http.post(apis.delVenueSpaceInfoById, row.id).then((res) => {
          if (res.data.code === 0) {
            // 删除后 更新列表
            this.$message.success("删除成功");
            // this.getDataQuery(this.copyData);
            this.fieldData.splice(index, 1);
          }
        });
      } else {
        this.fieldData.splice(index, 1);
      }
    },
    getRegion(data) {
      // console.log(data)
      this.form.region = data;
    },
    indexMethod(index) {
      let i = index + 1;
      if (i < 10) {
        i = `00${i}`;
      } else if (i < 100) {
        i = `0${i}`;
      }
      return i;
    },
    handleClick() {
      console.log("tab切换");
    },
    handleChange(value) {
      // console.log("价格", value)
      // this.spaceCameraPrice = v
      this.spaceCameraPrice = value;
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      console.log("this.fieldData++++++=", this.fieldData);
      // 这里写业务需求
      const fieldData = this.fieldData.map((item, index) => ({
        ...item,
        venueId: this.venuesId,
        price: `${parseFloat(item.price).toFixed(2)}`,
        vipPrice: `${parseFloat(item.vipPrice).toFixed(2)}`,
        spaceCode: (index + 1).toString(),
      }));

      console.log("最终的确定", fieldData);
      if (
        [...new Set(fieldData.map(item => item.spaceName))].length
        !== fieldData.length
      ) {
        this.$message.error(`请不要输入重复的场地名称`);
      } else {
        let spaceNameEmpty = true;
        let priceEmpty = true;
        fieldData.forEach((item) => {
          if (item.spaceName === "") {
            spaceNameEmpty = false;
          }
          // eslint-disable-next-line
          if (item.price === "" || isNaN(item.price)) {
            priceEmpty = false;
          }
        });
        if (!spaceNameEmpty) {
          this.$message.error("场地名称不能为空");
          return false;
        }
        if (!priceEmpty) {
          this.$message.error("每时段默认价格不能为空");
          return false;
        }
        this.$http
          .post(apis.venuevManagementInsertVenuevSpaceInfo, {
            input: fieldData,
            venuesId: this.venuesId,
            sportsProjectCode: this.sportsProjectCode,
          })
          .then((res) => {
            console.log("点击关闭");
            if (res.data.code === 0) {
              // 调用关闭方法，不要再写一次
              this.closeDialog();
              // this.$refs.confirmDialog.closeDialog();
            }
          });
      }
      return false;
      // console.log(`我执行了点确定的业务需求`);
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      // this.form = { ...data }
      this.venuesId = data;
      console.log("在这里获取回显的数据", data);
      this.copyData = data;
      // this.$http.get(`${apis.getSportsProjectCode}?sportsProjectCode=${this.sportsProjectCode}`).then((res) => {
      //   if (res.data.code === 0) {
      //     console.log("res===啦啦啦", res)
      //     this.fieldData = res.data.rows
      //   }
      // })
      // this.$http
      //   .post(apis.venuevManagementSelectVenuevSpaceInfo, data)
      //   .then((res) => {
      //     if (res.data.code === 0) {
      //       if (res.data.rows.length === 0) {
      //         this.fieldData = [{
      //           "spaceCode": Math.random().toFixed(2) * 300 + 100,
      //           "venueId": this.venuesId,
      //           price: '',
      //           spaceName: '',
      //         }]
      //       } else {
      //         this.fieldData = res.data.rows;
      //       }
      //       this.venuesId = data;
      //       // this.form = { ...res.data.rows };
      //       // this.form.status = 'first';
      //       // this.form.businessHours = res.data.rows.businessHours.split(',');
      //       // this.form.service = res.data.rows.service.split(',');
      //       // // console.log(res.data.rows.longitude)
      //       // this.form.longitude = res.data.rows.longitude;
      //       // this.form.latitude = res.data.rows.latitude;
      //       // this.form.point = {
      //       //   longitude: res.data.rows.longitude,
      //       //   latitude: res.data.rows.latitude,
      //       // }
      //     }
      //   });
      this.getSportsProject(this.getSportsProjectCode);
      this.checkList = [];
    },
    /**
     * @function 获取全部的角色（树状）
     */
    getRoleData() {
      this.$http.post(apis.User_GetAllRole).then((res) => {
        if (res.data.code === 0) {
          this.roleData = res.data.data.map((item) => {
            const items = { ...item };
            items.key = item.code;
            items.label = item.value;
            return items;
          });
        }
      });
    },
    getSportsProject(cb) {
      this.$http
        .get(`${apis.getSportsProjectList}?id=${this.venuesId}`)
        .then((res) => {
          if (res.data.code === 0) {
            if (res.data && res.data.rows && res.data.rows.length > 0) {
              this.sportList = res.data.rows;
              this.sportsProjectCode = res.data.rows[0].sportsProjectCode;
              this.sportRadio = res.data.rows[0].sportsProjectCode;
              if (cb) cb();
            } else {
              this.sportList = [];
              this.sportRadio = "";
            }
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    getSportsProjectCode() {
      this.$http
        .get(
          `${apis.getSportsProjectCode}?sportsProjectCode=${this.sportsProjectCode}&venueId=${this.venuesId}`,
        )
        .then((res) => {
          if (res.data.code === 0) {
            if (res.data.rows && res.data.rows.length > 0) {
              console.log("res.data.rows", res.data.rows);
              this.fieldData = res.data.rows.map((item) => {
                // item.liveCamerCode = ""
                item.spaceCameraPrice = "";
                item.iotId = "";
                // 直播摄像头默认取第一项
                if (item && item.cameraList && item.cameraList.length > 0) {
                  // item.liveCamerCode = item.cameraList[0].deviceCode
                  item.spaceCameraPrice = item.cameraList[0].spaceCameraPrice;
                  item.iotId = item.cameraList[0].id;
                }
                return item;
              });

              res.data.rows.forEach((item) => {
                this.cameraList = item.cameraList;
              });
            } else {
              this.fieldData = [
                {
                  spaceCode: "",
                  spaceName: "",
                  price: "",
                  cameraList: "",
                  cameraPrice: "",
                  spaceCameraPrice: "",
                  iotId: "",
                },
              ];
            }
          }
        });
    },
    sportSelect(v) {
      console.log(v);
    },
    uploadSuccess(res) {
      console.log(res);
      this.form.venueLogo = res.data.url;
    },
    uploadError(res) {
      console.log(res);
    },
    uploadSuccess1(res) {
      this.form.promotionPictures.push(res.data.url);
    },
    uploadError1(res) {
      console.log(res);
    },
    onChangeCode(code) {
      if (code) {
        this.sportsProjectCode = code;
      }
      this.getSportsProjectCode();
    },
  },
};
</script>
<style lang="less" scoped>
.bmap-wrap {
  width: 100%;
  height: 200px;
}
.sports-wrapper {
  .el-checkbox {
    min-width: 80px !important;
  }
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>

<!--
 * @Author: your name
 * @Date: 2020-03-23 11:19:40
 * @LastEditTime: 2021-12-02 15:39:43
 * @LastEditors: 林娴
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\venue\dialog\addDialog.vue
 -->
<template>
  <div class="dialog-container">
    <el-dialog
      :title="`${isEdit ? '修改场馆' : '新增场馆'}`"
      :visible.sync="show"
      @opened="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="630px"
    >
      <el-tabs class="taps-card" v-model="form.status" @tab-click="handleClick">
        <el-tab-pane label="基础信息" name="first">
          <el-form ref="form" :model="form" label-width="100px" :rules="rules">
            <el-row>
              <el-col :span="20" class="detail-label1">
                <el-form-item label="所属主体:"
                  ><span
                    class="detail-label-content"
                    v-for="(
                      item, index
                    ) in merchantAccountDetailInfoDTO.entityBaseInfoList"
                    :key="index"
                    @click="toSelect(index, item)"
                    :class="currentIndex === index ? 'active-text' : ''"
                    >{{ item.entityName }}</span
                  ></el-form-item
                >
              </el-col>
              <el-col :span="20" class="detail-label">
                <el-form-item label="商户名称:"
                  ><span>{{
                    merchantAccountDetailInfoDTO.merchantName
                  }}</span></el-form-item
                >
              </el-col>
              <el-col :span="20" class="detail-label">
                <el-form-item label="商户ID:"
                  ><span>{{
                    merchantAccountDetailInfoDTO.merchantId
                  }}</span></el-form-item
                >
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="主营方向:"
                  prop="mainDirection"
                  :required="isRequired"
                >
                  <el-select
                    v-model="form.mainDirection"
                    multiple
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in sporList"
                      :key="item.mainDirectionCode"
                      :label="item.mainDirectionName"
                      :value="item.mainDirectionCode"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="场馆名称:"
                  prop="venueName"
                  :required="isRequired"
                >
                  <el-input
                    clearable
                    v-model="form.venueName"
                    maxlength="20"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="可容纳人数:"
                  prop="accommodateQty"
                  :required="isRequired"
                >
                  <el-input
                    clearable
                    type="number"
                    @change="
                      (e) => (form.accommodateQty = e > 1000000 ? 1000000 : e)
                    "
                    v-model="form.accommodateQty"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="场馆地址:"
                  prop="address"
                  :required="isRequired"
                >
                  <el-input
                    clearable
                    v-model="form.address"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="场馆简介:"
                  prop="briefIntroduction"
                  :required="isRequired"
                >
                  <el-input
                    clearable
                    type="textarea"
                    rows="4"
                    v-model="form.briefIntroduction"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="详细信息" name="second">
          <el-form ref="form2" :model="form" label-width="110px" :rules="rules">
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label="场馆LOGO:"
                  prop="venueLogo"
                  :required="isRequired"
                >
                  <!-- <img :src="baseImage" alt="" srcset=""> -->
                  <el-upload
                    class="upload"
                    :show-file-list="false"
                    :with-credentials="true"
                    :action="apis.ImageUpload"
                    :httpRequest="uploadHttpDefault"
                    name="file"
                    accept=".png, .jpg"
                    :limit="999"
                    :on-success="uploadSuccess"
                    :on-error="uploadError"
                  >
                    <img
                      v-if="form.venueLogoPath"
                      :src="form.venueLogoPath"
                      alt
                      srcset
                      width="50"
                      height="50"
                    />
                    <!-- <el-button v-if="form.venueLogo === ''">上传</el-button> -->
                    <img
                      v-if="!form.venueLogo"
                      class="image-upload"
                      src="../../../../assets/images/upload_image.png"
                      alt=""
                      srcset=""
                    />
                    <div class="upload-images-tip">
                      图片尺寸不小于72*72px，图片格式png/jpg
                    </div>
                  </el-upload>
                  <!-- <el-input clearable v-model="form.venueLogo"></el-input> -->
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="场馆背景图片:"
                  prop="venueLogo"
                  :required="isRequired"
                >
                  <!-- <img :src="baseImage" alt="" srcset=""> -->
                  <el-upload
                    class="upload"
                    v-if="venuePicUrlFlag"
                    :show-file-list="false"
                    :with-credentials="true"
                    :action="apis.ImageUpload"
                    :httpRequest="uploadHttpDefault"
                    name="file"
                    accept=".png, .jpg"
                    :limit="999"
                    :on-success="(res) => {uploadSuccess1(res, 'venuePicUrl')}"
                    :on-error="uploadError"
                  >
                    <img
                      v-if="form.venuePicUrl"
                      :src="form.venuePicUrl"
                      alt
                      srcset
                      width="50"
                      height="50"
                    />
                    <!-- <el-button v-if="form.venueLogo === ''">上传</el-button> -->
                    <img
                      v-if="!form.venuePicUrl"
                      class="image-upload"
                      src="../../../../assets/images/upload_image.png"
                      alt=""
                      srcset=""
                    />
                    <div class="upload-images-tip">
                      图片尺寸不小于72*72px，图片格式png/jpg
                    </div>
                  </el-upload>
                  <!-- <el-input clearable v-model="form.venueLogo"></el-input> -->
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="联系电话:" prop="telephone">
                  <el-input clearable v-model="form.telephone" maxlength="20"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="13">
                <el-form-item
                  label="营业时间:"
                  prop="businessHours"
                  :required="isRequired"
                >
                  <el-checkbox-group v-model="form.businessHours" size="mini">
                    <el-checkbox-button
                      v-for="item in DROPDOWNBOX.WEEK"
                      :key="item.value"
                      :label="item.value"
                      >{{ item.label }}</el-checkbox-button
                    >
                  </el-checkbox-group>
                  <!-- <el-input clearable v-model="form.businessHours"></el-input> -->
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <el-form-item
                  label="开门时间:"
                  prop="openTime"
                  :required="isRequired"
                >
                  <el-time-select
                    v-model="form.openTime"
                    style="width: 100%"
                    :picker-options="{
                      start: '08:30',
                      step: '00:15',
                      end: '18:30',
                      maxTime: form.closeTime,
                    }"
                    placeholder="选择时间"
                  ></el-time-select>
                </el-form-item>
              </el-col>
              <el-col :span="13">
                <el-form-item
                  label="闭馆时间:"
                  prop="closeTime"
                  :required="isRequired"
                >
                  <el-time-select
                    style="width: 100%"
                    v-model="form.closeTime"
                    :picker-options="{
                      start: '08:30',
                      step: '00:15',
                      end: '22:30',
                      minTime: form.openTime,
                    }"
                    placeholder="选择时间"
                  ></el-time-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="地图图标:"
                  prop="mapIcon"
                  :required="isRequired"
                >
                  <!-- <img :src="baseImage" alt="" srcset=""> -->
                  <el-upload
                    class="upload"
                    :show-file-list="false"
                    :with-credentials="true"
                    :action="apis.ImageUpload"
                    :httpRequest="uploadHttpDefault"
                    name="file"
                    accept=".png, .jpg"
                    :limit="999"
                    :on-success="uploadMapIconSuccess"
                    :on-error="uploadError"
                  >
                    <img
                      v-if="form.mapIcon"
                      :src="form.mapIcon"
                      alt
                      srcset
                      width="50"
                      height="50"
                    />
                    <!-- <el-button v-if="form.venueLogo === ''">上传</el-button> -->
                    <img
                      v-if="!form.mapIcon"
                      class="image-upload"
                      src="../../../../assets/images/upload_image.png"
                      alt=""
                      srcset=""
                    />
                    <div class="upload-images-tip">
                      图片尺寸不小于33*44px，图片格式png/jpg
                    </div>
                  </el-upload>
                  <!-- <el-input clearable v-model="form.venueLogo"></el-input> -->
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  style="margin-bottom: 10px"
                  label="推广图片:"
                  prop="promotionPictures"
                  :required="isRequired"
                >
                  <el-upload
                    class="upload"
                    v-if="promotionPicturesFlag"
                    :show-file-list="false"
                    :with-credentials="true"
                    :action="apis.ImageUpload"
                    :httpRequest="uploadHttpDefault"
                    :headers="{
                      token: TOKEN,
                    }"
                    name="file"
                    accept=".png, .jpg"
                    :limit="100"
                    :on-success="(res) => {uploadSuccess1(res, 'promotionPictures', 'multi')}"
                    :on-error="uploadError1"
                  >
                    <!-- <el-button class="fl">上传</el-button> -->
                    <img
                      class="image-upload"
                      src="../../../../assets/images/upload_image.png"
                      alt=""
                      srcset=""
                    />
                    <div class="upload-images-tip">
                      图片尺寸不小于346x136px，图片格式png/jpg
                    </div>
                  </el-upload>
                  <div
                    class="image-wrap"
                    v-for="(item, index) in form.promotionPicturesPath"
                    :key="item"
                  >
                    <img :src="item" alt srcset width="50" height="50" />
                    <!-- <i class="el-icon-close" @click="removeImage(index)"></i> -->
                    <img
                      class="images-close"
                      @click="removeImage(index)"
                      src="../../../../assets/images/image_close.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <!-- <el-input clearable v-model="form.promotionPictures"></el-input> -->
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="服务:" prop="service">
                  <el-checkbox-group
                    class="service-wrapper"
                    v-model="form.service"
                  >
                    <el-checkbox
                      v-for="item in DROPDOWNBOX.VENUE_SERVICE"
                      :key="item.value"
                      :label="item.value"
                      >{{ item.label }}</el-checkbox
                    >
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="场馆状态:" prop="reservationSupport">
                  <el-switch
                    v-model="form.reservationSupport"
                    active-value="Y"
                    inactive-value="N"
                  ></el-switch>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="人脸识别:" prop="faceDeviceSupport">
                  <el-switch
                    v-model="form.faceDeviceSupport"
                    active-value="Y"
                    inactive-value="N"
                    active-text="支持"
                    inactive-text="不支持"
                  ></el-switch>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="VR支持:" prop="vrSupport">
                  <el-radio-group
                    v-model="form.vrSupport"
                    @change="changeVrPath"
                  >
                    <el-radio
                      v-for="item in DROPDOWNBOX.OM_VR_SUPPORT"
                      :key="item.value"
                      :label="item.value"
                    >
                      {{ item.label }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col v-if="form.vrSupport === 'Y'">
                <el-form-item label="VR地址:" prop="vrPath">
                  <el-input clearable v-model="form.vrPath"></el-input>
                </el-form-item>
              </el-col>

              <!-- <el-input size="mini" clearable v-model="keyword" placeholder="请输入关键字" /> -->
              <!-- <Bmap :point.sync="form.point" :keyword="keyword" v-if="showMap" :showWindow="false" @getAddress="getMapPonit"></Bmap> -->

              <!-- <el-col :span="24">
                <el-form-item label="地图标注" prop="point" :required="isRequired">
                  <div class="bmap-wrap">

                    <new-map ref="bMap" :address="form.mapAnnotation" :point.sync="form.point" :isEdit="isEdit" @getPoint="onGetPoint" @getAddress="getMapPonit"></new-map>
                  </div>
                </el-form-item>
              </el-col> -->

              <dialogMap
                ref="bMap"
                :address="form.mapAnnotation"
                :point.sync="form.point"
                :isEdit="isEdit"
                :lat.sync="form.point.lat"
                :lng.sync="form.point.lng"
                @getRegion="getRegion"
                :region.sync="form.region"
              >
              </dialogMap>
            </el-row>
          </el-form>
        </el-tab-pane>
      </el-tabs>

      <template slot="footer">
        <el-button
          class="footer-confirm"
          v-if="form.status === 'first'"
          @click="nextButton"
          >下一步</el-button
        >
        <el-button
          class="footer-confirm"
          v-if="form.status === 'second'"
          @click="sureEvent"
          >{{ $t("sure") }}</el-button
        >
        <el-button class="footer-cancel" @click="closeDialog">{{
          $t("cancel")
        }}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <!-- <div class="footer-confirm" @click="closeDialog">{{$t('sure')}}</div> -->
        <!-- <div class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</div> -->
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import mixin from "@/mixins/dialogMixin";
import twoStepDialogMixin from "@/mixins/twoStepDialogMixin";
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";
// import Bmap from "@/components/BMap.vue";
// import newMap from "./bMap";
import dialogMap from "@/components/dialogMap.vue";

export default {
  mixins: [mixin, twoStepDialogMixin],
  components: {
    // Bmap,
    // newMap,
    dialogMap,
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  mounted() {},
  activated() {},
  data() {
    return {
      baseImage: "",
      keyword: "", //搜索地图的关键字
      deeps: [
        { value: 0, label: "省" },
        { value: 1, label: "市" },
        { value: 2, label: "区/县" },
      ],
      form: new AddDialogClass("form"), // 实例化一个表单的变量
      rules: new AddDialogClass("rule", this.Language), // 实例化一个表单的规则
      roleData: [], // 角色树状的值
      apis,
      showMap: true,
      isRequired: true,
      promotionPicturesFlag: true,
      venuePicUrlFlag: true,
      sporList: [],
      id: "100b44c41b36446a95045e3d279be5b9",
      currentIndex: 0,
    };
  },
  computed: {
    ...mapState([
      "DROPDOWNBOX",
      "TOKEN",
      "venueList",
      "merchantAccountDetailInfoDTO",
    ]),
  },
  methods: {
    toSelect(index, data) {
      this.sporList = this.merchantAccountDetailInfoDTO.entityBaseInfoList[index].mainDirectionList;
      this.currentIndex = index;
      this.form.entityBaseInfoId = data.entityId;
      this.form.entityBaseInfoName = data.entityName;
      this.form.mainDirection = [];
    },
    getSportList() {
      this.sporList = this.merchantAccountDetailInfoDTO.entityBaseInfoList[0].mainDirectionList
    },
    // getRAdress(address, point) {
    //   console.log('getRAdress-add', address)
    //  this.form.mapAnnotation = address.address;
    //  this.form.point.lat = point.lat;
    //  this.form.latitude = point.lat;
    //  this.form.point.lng = point.lng;
    //  this.form.longitude = point.lng;
    // },
    /**
     * @function 关闭弹窗
     * @description 先初始化，然后传值到组件外部进行关闭
     **/
    async closeDialog() {
      await this.init();
      this.$refs.bMap.clearData();
      this.$emit("closeDialog", false);
    },
    ...mapMutations(["setVenueId", "setVenueList"]),
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.form = new AddDialogClass("form");
      console.log("我初始化了");
    },
    handleClick(value) {
      console.log("handleClick -> value", value);
      this.showMap = false;
      this.$nextTick(() => {
        this.showMap = true;
        if (value.name === "second") {
          this.$refs.bMap.init();
        }
      });
      console.log("tab切换");
    },
    getMapPonit(region) {
      console.log("getMapPonit -> region", region);
      this.form.region = region;
    },
    getRegion(region) {
      console.log("getMapPonit -> region", region);
      this.form.region = region;
    },
    onGetPoint(point) {
      console.log("onGetPoint -> point", point);
      this.form.point.lat = point.lat;
      this.form.point.lng = point.lng;
    },
    changeVrPath() {
      this.form.vrPath = "";
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    onSave() {
      // 这里写业务需求
      this.form.merchantAccountId = this.merchantAccountDetailInfoDTO.merchantId;
      this.form.merchantAccountName = this.merchantAccountDetailInfoDTO.merchantName;
      const formData = { ...this.form };
      formData.latitude = formData.point.lat;
      formData.longitude = formData.point.lng;
      formData.service = formData.service.join(",");
      formData.businessHours = formData.businessHours.join(",");
      formData.mainDirection = formData.mainDirection.join(",");
      this.$http
        .post(
          this.isEdit
            ? apis.venuevManagementUpdateVenuevManagement
            : apis.venuevManagementInsertVenuevManagement,
          formData,
        )
        .then((res) => {
          if (res.data.code === 0) {
            // 调用关闭方法，不要再写一次
            // 新增 刷新页面 保证新增场馆后 头部的场馆列表能有新的场馆
            // if (!this.isEdit) {
            this.reLoadVenune();
            // }
            this.closeDialog();
          }
        });

      // console.log(`我执行了点确定的业务需求`);
    },
    // 刷新页面后头部 保证新增场馆后 头部的场馆列表能有新的场馆
    reLoadVenune() {
      this.$http
        .post(apis.venuevManagement_list, { id: "" })
        .then((res) => {
          if (res.data.code === 0) {
            this.setVenueList(res.data.rows || []);
          }
        })
        .then((res) => {
          console.log(res);
        });
    },
    // 切换tap类型
    changeTap() {
      if (
        !this.form.mainDirection
        || !this.form.venueName
        || !this.form.accommodateQty
        || !this.form.address
        || !this.form.briefIntroduction
      ) {
        this.form.status = "first";
      } else {
        this.form.status = "second";
      }
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      // this.form = { ...data }
      this.$http
        .post(apis.venuevManagementSelectVenuevManagementById, data.id)
        .then((res) => {
          if (res.data.code === 0) {
            this.currentIndex = this.merchantAccountDetailInfoDTO.entityBaseInfoList.findIndex(
              value => value.entityId === res.data.rows.entityBaseInfoId,
            );
            this.sporList = this.merchantAccountDetailInfoDTO.entityBaseInfoList[this.currentIndex].mainDirectionList;
            this.form = {
              ...res.data.rows,
              ...{
                status: "first",
                businessHours: res.data.rows.businessHours
                  ? res.data.rows.businessHours.split(",")
                  : [],
                service: res.data.rows.service
                  ? res.data.rows.service.split(",")
                  : [],
                mainDirection: res.data.rows.mainDirection
                  ? res.data.rows.mainDirection.split(",")
                  : [],
                longitude: res.data.rows.longitude,
                latitude: res.data.rows.latitude,
                mapAnnotation: res.data.rows.mapAnnotation,
                point: {
                  lng: Number(res.data.rows.longitude),
                  lat: Number(res.data.rows.latitude),
                },
              },
            };
            this.id = res.data.rows.id;
            this.showMap = false;
            this.$nextTick(() => {
              this.showMap = true;
            });
            this.getCanvas(res.data.rows.venueLogoPath);
            // venueLogoPath: res.data.rows.venueLogo,
            // this.form.status = 'first';
            // this.form.businessHours = res.data.rows.businessHours.split(',');
            // this.form.service = res.data.rows.service.split(',');
            // console.log(res.data.rows.longitude)
            // this.form.longitude = res.data.rows.longitude;
            // this.form.latitude = res.data.rows.latitude;
            // this.form.venueLogoPath = res.data.rows.venueLogo;
            // this.form.point = {
            //   lng: res.data.rows.longitude,
            //   lat: res.data.rows.latitude,
            // }
            // this.form.venueLogoPath = this.form.venueLogo
            // this.form.promotionPicturesPath = [...this.form.promotionPictures]
          }
        });
    },
    /**
     * @function 获取全部的角色（树状）
     */
    getRoleData() {
      this.$http.post(apis.User_GetAllRole).then((res) => {
        if (res.data.code === 0) {
          this.roleData = res.data.data.map((item) => {
            const items = { ...item };
            items.key = item.code;
            items.label = item.value;
            return items;
          });
        }
      });
    },
    uploadSuccess(res) {
      this.form.venueLogo = res.data.path;
      this.form.venueLogoPath = res.data.url;
      // this.drawAndShareImage()
      this.getCanvas(res.data.url);
      this.$refs.form.validate("venueLogo");
    },
    uploadImageBase(file) {
      this.$http
        .post(apis.Base64ImageUpload, { Base64Data: file })
        .then((res) => {
          if (res.data.code === 200) {
            this.form.venueCircleLogo = res.data.data.path;
          }
        });
    },
    /* eslint-disable */
    async getCanvas(venueImages) {
      //canvas 加载图圆形处理
      let canvas = document.createElement("canvas");
      canvas.width = 54;
      canvas.height = 92;
      var ctx = canvas.getContext("2d");
      //加载图片
      let img = new Image();
      img.src = "";
      img.crossOrigin = "Anonymous"; //解决Canvas.toDataURL 图片跨域问题
      img.src = venueImages;
      img.onload = function () {
        let width = 54;
        let height = 54;
        let circle = {
          x: width / 2,
          y: height / 2,
          r: width / 2,
        };
        ctx.clearRect(0, 0, width, height);
        //开始路径画圆,剪切处理
        ctx.save();
        ctx.beginPath();
        ctx.arc(circle.x, circle.y, circle.r, 0, Math.PI * 2, false);
        ctx.clip(); //剪切路径
        ctx.drawImage(img, 0, 0, 54, 54);
        //恢复状态
        ctx.restore();
      };
      let img2 = new Image();
      img2.src = require("@/assets/images/map_logo.png");
      img2.onload = function () {
        ctx.drawImage(img2, 16, 58, 22, 34);
      };
      clearTimeout(this.timer);
      // this.timer = setTimeout(() => {
      //   this.uploadImageBase(canvas.toDataURL('image/png'))
      //   this.baseImage = canvas.toDataURL('image/png')
      //   }, 1000);
    },
    uploadError(res) {
      console.log(res);
    },
    uploadMapIconSuccess(res) {
      // 地图图标上传成功返回
      this.form.mapIcon = res.data.url;
      this.$refs.form.validate("mapIcon");
    },
    uploadSuccess1(res, key, type='single') {
      if (type === 'multi') {
        this.form[`${key}Path`].push(res.data.url);
        this.form[key].push(res.data.path);
      } else {
        this.form[`${key}Path`] = res.data.url
        this.form[key] = res.data.path
      }
      this[`${key}Flag`] = false;
      this.$nextTick(() => {
        this[`${key}Flag`] = true;
      });
      this.$refs.form.validate([key]);
    },
    uploadError1(err) {
      console.log(err);
      if ("string" == typeof err) {
        this.$message.error(err);
      } else {
        this.$message.error(
          "文件上传失败，可能是文件过大或网络问题，请更换文件或稍后重试"
        );
      }
    },
    openDialog() {
      if (!this.isEdit) {
        this.currentIndex = 0;
        this.form.entityBaseInfoId = this.merchantAccountDetailInfoDTO.entityBaseInfoList[0].entityId;
        this.form.entityBaseInfoName = this.merchantAccountDetailInfoDTO.entityBaseInfoList[0].entityName;
      }
      this.getSportList();
      this.showMap = false;
      this.$nextTick(() => {
        this.showMap = true;
        // this.$refs.bMap.init()
      });
    },
    updatePoint(point) {
      this.form.point = point;
      this.showMap = false;
      this.$nextTick(() => {
        this.showMap = true;
      });
    },
    removeImage(index) {
      this.form.promotionPicturesPath.splice(index, 1);
      this.form.promotionPictures.splice(index, 1);
    },
  },
};
</script>
<style lang="less" scoped>
.detail-label {
  height: 50px;
}
.detail-label1 {
  .active-text {
    color: #439bff;
  }
  .detail-label-content:nth-child(1) {
    margin-left: 0px;
  }
  .detail-label-content {
    cursor: pointer;
    margin-left: 10px;
  }
}
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color: rgba(168, 168, 168, 1);
  font-size: 12px;
  margin-top: -15px;
}
.bmap-wrap {
  width: 100%;
  height: 300px;
}
.image-wrap {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
.service-wrapper {
  .el-checkbox {
    min-width: 80px !important;
  }
}
</style>
